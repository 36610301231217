import firebase from "@/firebase";

export const db = firebase.firestore();

// export collection refs
export const userCollection = db.collection("users");
export const ts = firebase.firestore.FieldValue.serverTimestamp();

export default db;

// export default {
//   db,
//   userCollection,
//   projectCollection,
//   productCollection,
//   tasksCollection,
//   customerCollection,
//   ticketCollection
// }
