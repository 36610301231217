import firebase from "@/firebase";

export const provider = new firebase.auth.OAuthProvider("microsoft.com");
provider.setCustomParameters({
  tenant: "580e9bbc-998f-4c70-bd05-0131f2d2923d"
});

export const auth = firebase.auth();

export default auth;
