import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'

import firebase from "@/firebase";

Vue.config.productionTip = false;

Vue.use(firebase);
// Vue.use(Buefy);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
