import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/analytics";

const config = {
  apiKey: "AIzaSyDLWrv-aa6fTT9BkM5EJAEQTFdRtcp2Jpw",
  authDomain: "dc-project-closeout.firebaseapp.com",
  databaseURL: "https://dc-project-closeout.firebaseio.com",
  projectId: "dc-project-closeout",
  storageBucket: "dc-project-closeout.appspot.com",
  messagingSenderId: "954500225472",
  appId: "1:954500225472:web:8aee6a3def8373d8e6d025",
  measurementId: "G-FVWYD79QFK"
};

firebase.initializeApp(config);
firebase.analytics();

export default firebase;
