<template>
  <div class="home" v-if="loaded">
    <div class="title">
      <h1>Divcon Controls Signature Generator</h1>
    </div>
    <div class="login-wrap">
      <button v-if="!authed" @click="login" class="btn btn-divcon">
        <ion-icon name="logo-windows"></ion-icon>Log In via Office365
      </button>
      <button v-if="authed" @click="logout" class="btn btn-divcon">
        Sign Out
      </button>
      <p v-if="!authed">
        Please log in with your Divcon Controls account to create email
        signature.
      </p>
      <p v-if="user && profile">
        <strong>Copy and paste your generated signature into Outlook. email
          rluton@divconcontrols.com with issues.</strong>
      </p>
      <p v-if="user && profile">
        NOTE: This is part of an effort to standardize the look and feel of our
        communications.
        <br />With that said please refrain from adding additional information
        or verbage without consulting someone first.
      </p>
      <p v-if="user && !profile">Please log in again to generate signature.</p>
    </div>
    <div v-if="user && profile" class="signature-wrap">
      <div class="signature">
        <div style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">
          <div style="color: #9680FF;">
            <strong>{{ user.displayName }}</strong>
            <br />
            <span style="color: #2F1756; font-size: 14px;">{{
              profile.jobTitle || "Job Title"
              }}</span>
          </div>
          <div>
            <br />
            <img height="40px" width="140px"
              src="https://s3.us-west-2.amazonaws.com/divconcontrols.com/images/dc-logo-dark.png" />
          </div>
          <div style="color: #2F1756; font-size: 13px;">
            <span>1801 Royal Lane Suite 100</span>
            <br />
            <span>Farmers Branch, Texas 75229</span>
            <br />
            <span><strong style="color: #9680FF;">O:</strong>
              &nbsp;214.821.6958</span>&nbsp; | &nbsp;
            <span>
              <strong style="color: #9680FF;">C:</strong> &nbsp;{{
              formatPhone(profile.mobilePhone)
              }}
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.title {
  text-align: center;
  margin: 0;
  padding: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #666;
}
ion-icon {
  font-size: 16px;
  margin-right: 8px;
}
.signature-wrap {
  display: flex;
  justify-content: center;
}
.signature {
  padding: 20px;
  border: 2px solid #9680ff;
  /* width: 400px; */
}
.signature img {
  max-height: 40px;
  margin-top: 10px;
}
.login-wrap {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #4e5155;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.438rem 1.125rem;
  font-size: 0.894rem;
  line-height: 1.54;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
.btn-divcon {
  border-color: transparent;
  background: #9680ff;
  color: #fff;
}
</style>

<script>
import { auth, provider } from "@/firebase/auth";
import { ts, userCollection } from "@/firebase/db";

import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "home",
  data: () => ({
    loaded: false,
    authed: false,
    user: null,
    token: null,
    company: "Divcon Controls",
    address: {
      line1: "1801 Royal Lane Suite 100",
      line2: "Farmers Branch, Texas 75229"
    },
    profile: null
  }),
  created() {
    auth.onAuthStateChanged(user => {
      this.loaded = true;
      if (user) {
        this.authed = true;
        this.user = user;
      } else {
        this.authed = false;
        this.user = null;
        // console.log("No User");
      }
    });
  },
  methods: {
    async login() {
      try {
        const authData = await auth.signInWithPopup(provider);
        this.token = authData.credential.accessToken;
        const profile = await axios.get("https://graph.microsoft.com/v1.0/me", {
          headers: { Authorization: authData.credential.accessToken }
        });
        this.profile = profile.data;

        let {
          uid,
          displayName,
          email,
          phoneNumber,
          photoURL,
          emailVerified
        } = authData.user;

        if (!phoneNumber) {
          phoneNumber = "1234567890";
        }

        if (!this.profile.jobTitle) {
          this.profile.jobTitle = "Title Not Saved in AzureAD";
        }

        // console.log(authData);

        await userCollection.doc(uid).set(
          {
            displayName,
            email,
            phoneNumber,
            photoURL,
            uid,
            emailVerified,
            updated: ts,
            jobTitle: this.profile.jobTitle
          },
          {
            merge: true
          }
        );
      } catch (error) {
        throw new Error(error);
        // console.log(error);
      }
    },
    async logout() {
      return auth.signOut();
    },
    formatPhone(phone) {
      const parsed = parsePhoneNumberFromString(phone || "", "US");

      if (!parsed) {
        return "123.456.7890";
      }

      let cleanstring = parsed.nationalNumber;
      cleanstring = cleanstring.split("");
      cleanstring.splice(3, 0, ".");
      cleanstring.splice(7, 0, ".");
      // console.log(cleanstring);
      return cleanstring.join("");
    }
  }
};
</script>
